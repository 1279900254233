import React, { useState, useEffect } from 'react';

function HelloWorldComponent() {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/api/helloworld'); // Assuming your API endpoint is served from the root path /api
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setResponse(data);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {response && <p>Response: {response.message}</p>}
     
    </div>
  );
}

export default HelloWorldComponent;
