import './App.css';
import HelloWorldComponent from '../src/components/HelloWorld'; // Adjust the path accordingly
import DisplayImages from '../src/components/DisplayImages'; // Adjust the path accordingly


function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Other components or elements */}
        <HelloWorldComponent />
      </header>
      <div>
        <DisplayImages />
      </div>
    </div>
  );
}

export default App;

